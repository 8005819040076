import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "bg-gray-100 p-6 rounded-t-lg" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "mt-2 text-sm text-gray-700" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "text-sm text-gray-900 p-6" }
const _hoisted_12 = { class: "flex items-center justify-end space-x-4 p-6 border-t border-gray-100" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_CircleSpinner = _resolveComponent("CircleSpinner")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: _ctx.width
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DialogCloseButton, { onClick: _ctx.cancel }, null, 8, ["onClick"]),
        _createVNode(_component_DialogTitle, {
          class: "text-xl font-medium leading-6 line-clamp-2 text-gray-900 capitalize",
          as: "h3"
        }, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.title), 1))
              : (_ctx.type === 'file')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.state.mode === 'create' ? 'Upload' : 'Edit') + " " + _toDisplayString(_ctx.type), 1))
                : (_ctx.type === 'management')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.state.mode === 'create' ? 'Add' : 'Edit') + " " + _toDisplayString(_ctx.type), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.state.mode === 'create' ? 'Create' : 'Edit') + " " + _toDisplayString(_ctx.type), 1))
          ]),
          _: 1
        }),
        _createElementVNode("p", _hoisted_6, [
          (_ctx.description)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.description), 1))
            : (_ctx.state.mode === 'create')
              ? (_openBlock(), _createElementBlock("span", _hoisted_8, "Get started by filling in the information below to create your new " + _toDisplayString(_ctx.type.toLowerCase()) + ".", 1))
              : (_ctx.state.mode === 'create' && _ctx.type === 'management')
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Get started by filling in the information below to add your new management."))
                : (_openBlock(), _createElementBlock("span", _hoisted_10, "Update this " + _toDisplayString(_ctx.type.toLowerCase()) + " by filling in the information below.", 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.state.error)
          ? (_openBlock(), _createBlock(_component_Alert, {
              key: 0,
              class: "mb-4",
              theme: "danger"
            }, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.error), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.form), {
          ref: "FormRef",
          record: _ctx.state.record,
          meta: _ctx.meta,
          onRecordUpdated: _ctx.mergeChanges,
          onLoading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state.loading = $event)),
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.save(false)), ["prevent"])),
          onError: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.error = $event))
        }, null, 40, ["record", "meta", "onRecordUpdated"]))
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("button", {
          theme: "secondary",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
        }, "Cancel"),
        (_ctx.state.mode === 'create' && _ctx.allowRepeat)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              theme: "secondary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.save(true)))
            }, [
              (_ctx.state.loading)
                ? (_openBlock(), _createBlock(_component_CircleSpinner, {
                    key: 0,
                    class: "inline-button-spinner",
                    src: "@/assets/spinner.svg"
                  }))
                : _createCommentVNode("", true),
              _createTextVNode("Save and Add Another")
            ]))
          : _createCommentVNode("", true),
        (_ctx.type === 'file')
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              theme: "copper",
              disabled: _ctx.state.actionsDisabled,
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.save(false)))
            }, [
              (_ctx.state.loading)
                ? (_openBlock(), _createBlock(_component_CircleSpinner, {
                    key: 0,
                    class: "inline-button-spinner",
                    src: "@/assets/spinner.svg"
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(_toDisplayString(_ctx.state.record && _ctx.state.record.id ? 'Update' : 'Upload'), 1)
            ], 8, _hoisted_13))
          : (_ctx.type === 'management')
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                theme: "copper",
                disabled: _ctx.state.actionsDisabled,
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.save(false)))
              }, [
                (_ctx.state.loading)
                  ? (_openBlock(), _createBlock(_component_CircleSpinner, {
                      key: 0,
                      class: "inline-button-spinner",
                      src: "@/assets/spinner.svg"
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(_toDisplayString(_ctx.state.record && _ctx.state.record.id ? 'Update' : 'Save'), 1)
              ], 8, _hoisted_14))
            : (_openBlock(), _createElementBlock("button", {
                key: 3,
                theme: "copper",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.save(false))),
                disabled: _ctx.state.actionsDisabled,
                "data-intercom-target": "update-create-button"
              }, [
                (_ctx.state.loading)
                  ? (_openBlock(), _createBlock(_component_CircleSpinner, {
                      key: 0,
                      class: "inline-button-spinner",
                      src: "@/assets/spinner.svg"
                    }))
                  : _createCommentVNode("", true),
                _createTextVNode(_toDisplayString(_ctx.state.record && _ctx.state.record.id ? 'Update' : 'Create'), 1)
              ], 8, _hoisted_15))
      ])
    ]),
    _: 1
  }, 8, ["showing", "onEsc", "width"]))
}