import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-e679b4a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "relative",
  role: "listbox"
}
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "absolute inset-y-0 left-0 flex items-center pl-3 text-copper-800"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Listbox, {
      modelValue: _ctx.state.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.model) = $event)),
      disabled: _ctx.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ListboxButton, {
          class: _normalizeClass(["relative flex items-center space-x-3 w-full p-0 m-0 text-left bg-white cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500", [!_ctx.state.model || _ctx.disabled ? 'text-gray-400' : 'text-gray-800']]),
          role: "listbox-trigger"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", null, _toDisplayString(_ctx.state.model ? _ctx.state.model[_ctx.labelField] : _ctx.placeholder), 1)
            ]),
            _createVNode(_component_ChevronDownIcon, {
              class: "w-5 h-5",
              "aria-hidden": "true"
            })
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_Transition, {
          "leave-active-class": "transition duration-100 ease-in",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ListboxOptions, {
              class: _normalizeClass(["absolute left-0 z-50 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5", ['focus:outline-none sm:text-sm', _ctx.variant]])
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    key: option,
                    value: option,
                    disabled: option.disabled,
                    as: "template"
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass(["cursor-default select-none relative py-2 pr-6 transition duration-150", [option.disabled ? 'text-gray-500' : active ? 'text-gray-900 bg-gray-100' : 'text-gray-900', _ctx.state.model ? 'pl-10' : 'pl-4']])
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["block truncate", [_ctx.state.model && _ctx.state.model[_ctx.valueField] === option[_ctx.valueField] ? 'font-medium text-copper-800' : 'font-normal']])
                        }, _toDisplayString(option[_ctx.labelField]), 3),
                        (_ctx.state.model && _ctx.state.model[_ctx.valueField] === option[_ctx.valueField])
                          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                              _createVNode(_component_CheckIcon, {
                                class: "w-5 h-5",
                                "aria-hidden": "true"
                              })
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value", "disabled"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["class"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "disabled"])
  ]))
}