import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9c549cd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex rounded-t-lg" }
const _hoisted_2 = { class: "flex flex-col w-full" }
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: _ctx.width
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DialogCloseButton, {
            onClick: _ctx.cancel,
            theme: "primary"
          }, null, 8, ["onClick"]),
          _createVNode(_component_DialogTitle, {
            class: "p-6 text-lg font-medium leading-6 line-clamp-2 text-gray-900 mb-0",
            as: "h3"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true)
            ]),
            _: 3
          }),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "message", {}, undefined, true)
          ])
        ])
      ])
    ]),
    _: 3
  }, 8, ["showing", "onEsc", "width"]))
}