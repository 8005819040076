import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b1310cea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex p-6 gap-6 pb-0 rounded-t-lg" }
const _hoisted_2 = { class: "flex flex-col rounded-t-lg truncate w-full" }
const _hoisted_3 = { class: "text-sm text-gray-700 text-left leading-6 whitespace-normal" }
const _hoisted_4 = { class: "flex items-center justify-end space-x-4 p-6" }
const _hoisted_5 = {
  key: 0,
  class: "space-x-4"
}
const _hoisted_6 = ["theme", "disabled"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationIconOutline = _resolveComponent("ExclamationIconOutline")!
  const _component_check_circle_icon = _resolveComponent("check-circle-icon")!
  const _component_information_circle_icon = _resolveComponent("information-circle-icon")!
  const _component_DialogCloseButton = _resolveComponent("DialogCloseButton")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_BaseDialog = _resolveComponent("BaseDialog")!

  return (_openBlock(), _createBlock(_component_BaseDialog, {
    showing: _ctx.state.showing,
    onEsc: _ctx.cancel,
    width: _ctx.width
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.theme)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["flex flex-shrink-0 justify-center items-center rounded-full h-10 w-10", _ctx.theme])
            }, [
              (_ctx.theme === 'warning')
                ? (_openBlock(), _createBlock(_component_ExclamationIconOutline, {
                    key: 0,
                    class: "h-7 w-7"
                  }))
                : (_ctx.theme === 'success')
                  ? (_openBlock(), _createBlock(_component_check_circle_icon, {
                      key: 1,
                      class: "h-7 w-7"
                    }))
                  : (_ctx.theme === 'danger')
                    ? (_openBlock(), _createBlock(_component_ExclamationIconOutline, {
                        key: 2,
                        class: "h-7 w-7"
                      }))
                    : (_ctx.theme === 'info')
                      ? (_openBlock(), _createBlock(_component_information_circle_icon, {
                          key: 3,
                          class: "h-7 w-7"
                        }))
                      : _createCommentVNode("", true)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DialogCloseButton, {
            onClick: _ctx.cancel,
            theme: "primary"
          }, null, 8, ["onClick"]),
          _createVNode(_component_DialogTitle, {
            class: "truncate text-lg font-medium leading-6 text-gray-900 mb-4",
            style: {"max-width":"90%"},
            as: "h3"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {
                record: _ctx.state.record
              }, undefined, true)
            ]),
            _: 3
          }),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "message", {
              record: _ctx.state.record
            }, undefined, true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "actions", {
          record: _ctx.state.record
        }, () => [
          (!_ctx.info)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("button", {
                  theme: "secondary",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
                }, "Cancel"),
                _createElementVNode("button", {
                  theme: _ctx.theme === 'danger' ? 'danger' : 'copper',
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args))),
                  disabled: _ctx.state.working
                }, "Confirm", 8, _hoisted_6)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", {
                  theme: "primary",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
                }, "Okay")
              ]))
        ], true)
      ])
    ]),
    _: 3
  }, 8, ["showing", "onEsc", "width"]))
}